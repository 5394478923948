import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Input, Table, Spin, Divider } from "antd";
import millify from "millify";
import { getETB } from "../api/ProductMasterAPI";

const ETB = () => {
  const [filtered, setFiltered] = useState([]);

  const etb = useQuery({
    queryKey: ["etb"],
    queryFn: getETB,
    refetchOnWindowFocus: false,
    staleTime: 6 * 60 * 60 * 1000,
  });

  useEffect(() => {
    setFiltered(etb?.data);
  }, [etb.data]);

  const dataSource = filtered;
  const columns = [
    {
      title: "symbol",
      dataIndex: "symbol",
      align: "center",
    },
    {
      title: "shares",
      dataIndex: "shares",
      align: "center",
    },
    {
      title: "rate",
      dataIndex: "rate",
      align: "center",
      sorter: (a, b) => a.rate - b.rate,
    },
    {
      title: "Company",
      dataIndex: "CompanyName",
      align: "left",
      responsive: ["lg"],
    },
    {
      title: "ISIN",
      dataIndex: "ISIN",
      align: "center",
    },
    {
      title: "CUSIP",
      dataIndex: "CUSIP",
      align: "center",
      responsive: ["xl"],
    },
  ];

  const onSearch = (value) => {
    let upVar = value.toUpperCase();
    setFiltered(
      etb.data.filter(
        (x) =>
          x.symbol.includes(upVar) || x.ISIN.includes(upVar) || x.CUSIP.includes(upVar) || x.CompanyName.includes(upVar)
      )
    );
  };

  return (
    <div>
      {" "}
      {etb.isLoading ? (
        <div className="loader">
          <Spin tip="Loading..." size="large" />
        </div>
      ) : (
        <div>
          <Input.Search
            placeholder="input search data"
            allowClear
            enterButton="Search"
            size="middle"
            // disabled={!fractional?.data}
            onSearch={onSearch}
          />
          <Divider />
          <Table
            rowKey="ISIN"
            dataSource={dataSource}
            columns={columns}
            size="small"
            // pagination={false}
            pagination={{
              position: ["bottomRight"],
              defaultPageSize: 20,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ETB;
