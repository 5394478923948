import { PageContainer } from "@ant-design/pro-components";
import { Row, Col } from "antd";
import ProductMaster from "./components/ProductMaster";
import ProductMasterGBR from "./components/ProductMasterGBR";
import ProductMasterCAN from "./components/ProductMasterCAN";
import Fractional from "./components/Fractional";
import FixedIncome from "./components/FixedIncome";
import PTP from "./components/PTP";
import ETB from "./components/ETB";
import HTB from "./components/HTB";
import "./App.css";

function App() {
  return (
    <div>
      <Row>
        <Col
          xs={{ offset: 0, span: 24 }}
          sm={{ offset: 0, span: 24 }}
          md={{ offset: 0, span: 24 }}
          lg={{ offset: 0, span: 24 }}
          xl={{ offset: 2, span: 20 }}
          xxl={{ offset: 2, span: 20 }}
        >
          <PageContainer
            ghost
            header={{
              title: "PRODUCT MASTER",
              breadcrumb: {},
            }}
            tabList={[
              {
                tab: "Product Master",
                key: "ProductMaster",
                children: <ProductMaster />,
              },
              {
                tab: "UK Product Master",
                key: "ProductMasterGBR",
                children: <ProductMasterGBR />,
              },
              {
                tab: "CAD Product Master",
                key: "ProductMasterCAD",
                children: <ProductMasterCAN />,
              },
              {
                tab: "Fractional",
                key: "Fractional",
                children: <Fractional />,
              },
              {
                tab: "Fixed Income",
                key: "FixedIncome",
                children: <FixedIncome />,
              },
              {
                tab: "PTP",
                key: "PTP",
                children: <PTP />,
              },
              {
                tab: "Easy to Borrow",
                key: "ETB",
                children: <ETB />,
              },
              {
                tab: "Hard to Borrow",
                key: "HTB",
                children: <HTB />,
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
}

export default App;
