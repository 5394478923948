import axios from "axios";

export function getProductMaster() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/productmaster`).then((res) => res.data);
}

export function getProductMaster20() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/productmaster20`).then((res) => res.data);
}

export function getProductMasterGBR() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/productmaster_gbr`).then((res) => res.data);
}

export function getProductMasterGBR20() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/productmaster_gbr20`).then((res) => res.data);
}

export function getProductMasterCAN() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/productmaster_can`).then((res) => res.data);
}

export function getProductMasterCAN20() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/productmaster_can20`).then((res) => res.data);
}

export function getFractional() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/fractional`).then((res) => res.data);
}

export function getPTP() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/ptp`).then((res) => res.data);
}

export function getETB() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/etb`).then((res) => res.data);
}

export function getHTB() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/htb`).then((res) => res.data);
}

export function getFixedIncome() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/fixedincome`).then((res) => res.data);
}