import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Input, Table, Spin, Divider } from "antd";
import millify from "millify";
import { getPTP } from "../api/ProductMasterAPI";

const PTP = () => {
  const [filtered, setFiltered] = useState([]);

  const ptp = useQuery({
    queryKey: ["ptp"],
    queryFn: getPTP,
    refetchOnWindowFocus: false,
    staleTime: 6 * 60 * 60 * 1000,
  });

  useEffect(() => {
    setFiltered(ptp?.data);
  }, [ptp.data]);

  const dataSource = filtered;
  const columns = [
    {
      title: "Symbol",
      dataIndex: "Symbol",
      align: "center",
    },
    {
      title: "MIC",
      dataIndex: "MIC",
      align: "center",
      responsive: ["lg"],
    },
    {
      title: "Company",
      dataIndex: "CompanyName",
      align: "left",
      responsive: ["lg"],
    },
    {
      title: "Last Price",
      dataIndex: "LastPrice",
      align: "right",
      responsive: ["lg"],
    },
    {
      title: "Previous Close",
      dataIndex: "PreviousClose",
      align: "right",
      sorter: (a, b) => a.PreviousClose - b.PreviousClose,
    },
    {
      title: "Open",
      dataIndex: "OpeningPrice",
      align: "right",
      sorter: (a, b) => a.OpeningPrice - b.OpeningPrice,
      render: (text, record) => {
        let color = text >= record.PreviousClose ? "green" : text == 0 ? "black" : "red";
        return <div style={{ color }}>{text}</div>;
      },
    },
    {
      title: "Volume",
      dataIndex: "Volume",
      align: "right",
      sorter: (a, b) => a.Volume - b.Volume,
      render: (text) => millify(Number(text)),
    },
    {
      title: "Change",
      dataIndex: "Change",
      align: "right",
      sorter: (a, b) => a.Change - b.Change,
      render: (text) => {
        let color = text > 0 ? "green" : text < 0 ? "red" : "black";
        return <div style={{ color }}>{Number(text).toFixed(2)}%</div>;
      },
    },
    {
      title: "ISIN",
      dataIndex: "ISIN",
      align: "center",
    },
    {
      title: "CUSIP",
      dataIndex: "CUSIP",
      align: "center",
      responsive: ["xl"],
    },
    {
      title: "Market Cap",
      dataIndex: "MarketCap",
      align: "right",
      responsive: ["xl"],
      sorter: (a, b) => a.MarketCap - b.MarketCap,
      render: (text) => millify(Number(text)),
    },
    {
      title: "30 Avg Volume",
      dataIndex: "AvgVolume30",
      align: "center",
      responsive: ["xxl"],
      sorter: (a, b) => a.AvgVolume30 - b.AvgVolume30,
      render: (text) => millify(Number(text)),
    },
    {
      title: "52W High",
      dataIndex: "High52W",
      align: "right",
      responsive: ["xxl"],
      sorter: (a, b) => a.High52W - b.High52W,
    },
    {
      title: "52W Low",
      dataIndex: "Low52W",
      align: "right",
      responsive: ["xxl"],
      sorter: (a, b) => a.Low52W - b.Low52W,
    },
  ];

  return (
    <div>
      {" "}
      {ptp.isLoading ? (
        <div className="loader">
          <Spin tip="Loading..." size="large" />
        </div>
      ) : (
        <div>
          <Table
            rowKey="ISIN"
            dataSource={dataSource}
            columns={columns}
            size="small"
            pagination={false}
            // pagination={{
            //   position: ["bottomRight"],
            //   defaultPageSize: 20,
            // }}
          />
        </div>
      )}
    </div>
  );
};

export default PTP;
